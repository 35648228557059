/* Header component */
.header {
    position: relative;
    width: 100%;
    color: white;
    background-color: black;
    z-index: 9999;
}
.footer {
    font-size: 0.75rem;
    position: relative;
    background-color: black;
    color: white;
    text-align: center;
} 
.nav-logo {
    position: relative;
    color: white;
    text-decoration: none;
    margin-left: 0.5rem;
    font-weight: bold;
}
/* Home component */
.homepage-image {
    object-fit: fill;
}
.image-text {
    position: absolute;
    transform: translate(-50%, -50%);
    color: black;
    font-weight: bold;
    text-align: center;
}
.hometext{
    background-color: rgb(244, 236, 236);
    display: block;
    border: #000000 3px solid;
    padding: 0.5rem;
    text-indent: 1rem;
}
.hometext p {
    margin: 0;
    text-align: center;
    font-style: italic;
    font-weight: bold;
}
.home-button {
    padding: 0.6rem;
    justify-content: space-between;
    display: flex;
}
.home-button-projects {
    border: none;
    background-color: white;
}
.home-button-contact {
    border: none;
    background-color: white;
}
/* About component */
.about {
    background-image: url('assets/aboutbgImage.jpg');
    background-size: cover;
    background-position: relative;
    object-fit: cover;
    width: 100%;
    height: 100%;
    display: block;
}
.about-section {
    text-align: center;
}
.about-section p {
    font-style: italic;
    box-shadow: inset 0 0 10px #000000;
}
.about-services-card {
    max-height: 300px;
    box-shadow: inset 0 0 10px #000000;
    border: #000000 1.5px solid;
}
.about-services-card-title {
    font-weight: bold;
}
.brands {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.about-brands {
    border: #000000 1px solid;
}
.about-brands img {
    width: 100%;
    height: 100%;
    position: relative;
}
/* Projects component */
.projects-card {
    max-width: 350px;
    border: #000000 1.5px solid;
}
.swiper-image {
    width: 100%;
    height: 300px;
}
/*Contact Component*/
.contact {
    float: none;
    background-image: url('assets/contactbgImage.jpg');
    background-size: cover;
    background-position: center;
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.contact-container {
    margin: 1rem auto 1rem;
    margin-bottom: 1rem;
    padding: 1rem;
    box-shadow: inset 0 0 10px #000000;
    background-image: url('assets/contactbg.jpg');
    background-size: fill;
    background-position: center;
}
.contact-container h6 {
    text-align: center;
}
.contactForm {
    text-align: center;
}
.contactForm label {
    font-weight: bold;
    margin: 0.75rem;
    display: block;
    text-align: left;
}
.contactForm label input {
    display: block;
    width: 90%;
    padding: 0.25rem;
    margin: 0.25rem;
    border: #000000 1.5px solid;
}
.contactForm label select{
    width: 90%;
    padding: 0.25rem;
    margin: 0.25rem;
    border: #000000 1.5px solid;
}
.contactForm label textarea {
    width: 90%;
    padding: 0.25rem;
    margin: 0.25rem;
    border: #000000 1.5px solid;
}
.contactForm button {
    background-color: black;
    color: white;
    align-self: center;
    font-weight: bold;
    box-shadow: inset 0 0 10px #000000;
}
.contactForm button:hover {
    background-color: rgb(125, 110, 110);
}
.contact-card {
    align-self: center;
    border: #000000 1.5px solid;
}
.contact-card h5 {
    font-weight: bold;
    text-align: center;
}
.contact-card address {
    text-align: left;
}
.contact-card p {
    text-align: left;
}
.contact-card a {
    text-align: left;
}
/* Extra small screen */
@media (max-width: 278px){
.footer {
    font-size: 0.5rem;
}
/* Navbar component */
.nav-toggle {
    position: absolute;
    top: 0;
    right: 0;
    color: white;
    background-color: black;
    font-size: 1.2rem;
    cursor: pointer;
    border: none;
    }
.nav-logo {
    font-size: 0.8rem;
}    
.nav-list {
    list-style: none;
    margin: 0.25rem;
    padding: 0;
    justify-content: left;
    font-weight: bold;
    }
.nav-link {
    margin: 0.5rem;
    }
.nav-menu {
    position: absolute;
    top: 100%;
    width: 40%;
    right: 0;
    font-size: 0.6rem;
    background-color: grey;        
    }
.nav-close {
    position: fixed;
    top: 0;
    right: 0;
    font-size: 1.1rem;
    color: white;
    background-color: black;
    /*cursor: pointer;*/
    border: none;
}
/* Home component */
.image-text {
    top: 14%;
    left: 29%;
    font-size: 0.45rem;
}
.hometext {
    padding: 1rem;
}
.hometext p{
    font-size: 0.5rem;
}
.home-services {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.home-button-projects {
    font-size: 0.7rem;
}
.home-button-contact {
    font-size: 0.7rem;
}
/* About component */
.about-section {
    padding-top: 0.5rem;
    margin-top: 0;
}
.about-section p {
    font-size: 0.6rem;
    margin: 1rem;
    padding: 1rem;
}
.about-services {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.about-services-card { 
    width: 40%;
    margin: 1rem auto 1rem;
    height: 20vh;
}
.about-services-card-title {
    font-size: 0.45rem;
}
.about-services-image {
    width: 100%;
    height: 80px;
    object-fit: initial;
}
.brands {
    justify-content: space-evenly;
} 
.about-brands {
    width: 60px;
    height: 30px;
    margin: 0.1rem 0.1rem 0.2rem;
}
/* Projects component */
.projects {
    display: flex;
    flex-direction: column;
}
.projects-card {
    margin: 1rem auto 1rem;
    width: 90%;
}
.swiper-image {
    object-fit:inherit;
    object-position: center;
}
/* Contact component */
.contact {
    display: flex;
    flex-direction: column;
    float: none;
}
.contact-container {
    width: 90%;
    max-width: 600px;
}
.contact-container h3 {
    font-size: 0.85rem;
}
.contact-container h6 {
    font-size: 0.6rem;
}
.contactForm label {
    font-size: 0.75rem;
}
.contactForm button {
    padding: 0.35rem;
    font-size: 0.75rem;
}
.contact-card {
    margin: 0 auto 1rem;
    width: 90%;
}
.contact-card h5 {
    font-size: 0.8rem;
}
.contact-card address {
    font-size: 0.65rem;
}
.contact-card p {
    font-size: 0.65rem;
}
.contact-card a {
    font-size: 0.7rem;
}
}
/* small screen */
@media (min-width: 279px) {
/* Navbar component */
.nav-toggle {
    position: absolute;
    top: 0;
    right: 0;
    color: white;
    background-color: black;
    font-size: 1.5rem;
    cursor: pointer;
    border: none;
}
.nav-list {
    list-style: none;
    margin: 0.5rem;
    padding: 0;
    justify-content: left;
    font-weight: bold;
    height: 100%;
    font-size: 0.9rem;
}
.nav-link {
    margin: 1rem;
}
.nav-logo {
    font-size: 1.2rem;
}
.nav-menu {
    position: absolute;
    top: 100%;
    width: 40%;
    right: 0;
    font-size: 0.8rem;
    background-color: grey;     
}
.nav-close {
    position: fixed;
    top: 0;
    right: 0;
    font-size: 1.5rem;
    color: white;
    background-color: black;
    border: none;
}
/* Home component */
.image-text {
    top: 14%;
    left: 29%;
    font-size: 0.6rem;
}
.hometext{
    font-size: 0.7rem;
}
.hometext p {
    padding: 0.6rem;
}
.home-services {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.home-button-projects {
    font-size: 1.2rem;
}
.home-button-contact {
    font-size: 1.2rem;
}
/* About component */
.about-section {
    padding-top: 0.5rem;
    margin-top: 0;
}
.about-section p {
    font-size: 0.6rem;
    margin: 1rem;
    padding: 1rem;
}
.about-services {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.about-services-card { 
    width: 40%;
    margin: 1rem auto 1rem;
    height: 30vh;
}
.about-services-card-title {
    font-size: 0.6rem;
}
.about-services-image {
    width: 100%;
    height: 140px;
    object-fit: initial;
}
.brands {
    justify-content: space-evenly;
} 
.about-brands {
    width: 80px;
    height: 40px;
    margin: 0.1rem 0.1rem 0.2rem;
}
/* Projects component */
.projects {
    display: flex;
    flex-direction: column;
}
.projects-card {
    margin: 1rem auto 1rem;
    width: 90%;
}
.swiper-image {
    object-fit:inherit;
    object-position: center;
}
/* Contact component */
.contact {
    display: flex;
    flex-direction: column;
    float: none;
}
.contact-container {
    width: 90%;
    max-width: 600px;
}
.contact-container h6 {
    font-size: 0.8rem;
}
.contactForm label {
    font-size: 1rem;
}
.contactForm button {
    padding: 0.5rem;
}
.contact-card {
    margin: 0 auto 1rem;
    width: 90%;
}
.contact-card h5 {
    font-size: 1rem;
}
.contact-card address {
    font-size: 0.8rem;
}
.contact-card p {
    font-size: 0.7rem;
}
.contact-card a {
    font-size: 0.8rem;
}
}
@media (min-width: 600px) {
/* Navbar component */
.nav {
    height: 100%;
}   
.nav-toggle,
.nav-close {
    display: none;
}
.nav-logo {
    margin-left: 1rem;
    font-size: 1.25rem;
    margin-top: 0;
}   
.nav-list-web {
    display: flex;
    flex-direction: row;
    column-gap: 0.2rem;
    font-size: 1rem;
    list-style: none;
    margin: 0;
}
/* Home component */
.image-text {
    top: 20%;
    left: 29%;
    font-size: 1.25rem;
}
.hometext{
    font-size: 1rem;
}
.hometext p {
    padding: 1.5rem;
}
.home-services {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
}
.home-button-projects {
    font-size: 1.5rem;
}
.home-button-contact {
    font-size: 1.5rem;
}
/* About component */
.about-section {
    padding-top: 0.5rem;
    margin-top: 0;
}
.about-section p {
    font-size: 1rem;
    margin: 1rem;
    padding: 1rem;
}
.about-services {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
}
.about-services-card { 
    width: 100%;
    margin: 0.5rem;
    height: 100%;
}
.about-services-card-title {
    font-size: 0.8rem;
}
.about-services-image {
    width: 100%;
    height: 200px;
    object-fit: fill;
}
.about-brands {
    width: 80px;
    height: 40px;
    margin: 0.5rem auto 1rem;
}
/* Projects component */
.projects-main{
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content:space-around
}
.projects {
    margin:auto;
}
.projects-card {
    margin: 1rem;
    width: 100%;
    height: 100%;
}
.swiper-image {
    object-fit: fill;
}
/* Contact component */
.contact {
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
}
.contact-container {
    width: min(60%, 360px);
}
.contact-container h3 {
    font-size: 1.25rem;
    text-align: center;
}
.contact-container h6 {
    font-size: 0.8rem;
}
.contactForm label {
    font-size: 1rem;
}
.contactForm button {
    padding: 0.5rem;
}
.contact-card {
    margin: 1rem auto 1rem;
    width: 35%;
    height: 360px;
}
.contact-card h5 {
    font-size: 1.25rem;
}
.contact-card address {
    font-size: 0.8rem;
}
.contact-card p {
    font-size: 0.8rem;
}
.contact-card a {
    font-size: 0.8rem;
}
}
/* Large screen */
@media (min-width: 1145px) {
.footer {
    font-size: 1rem;
}
/* Navbar component */
.nav {
    height: 100%;
}   
.nav-toggle,
.nav-close {
    display: none;
}
.nav-logo {
    margin-left: 1rem;
    font-size: 1.5rem;
    margin-top: 0;
}   
.nav-list-web {
    display: flex;
    flex-direction: row;
    column-gap: 0.4rem;
    font-size: 1.3rem;
    list-style: none;
    margin: 0;
}
/* Home component */
.image-text {
    top: 18%;
    left: 22%;
    font-size: 1.5rem;
}
.hometext{
    font-size: 1.2rem;
}
.hometext p {
    padding: 1.5rem;
}
.home-services {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.home-button-projects {
    font-size: 1.75rem;
}
.home-button-contact {
    font-size: 1.75rem;
}
/* About component */
.about-section {
    padding-top: 0.5rem;
    margin-top: 0;
}
.about-section p {
    font-size: 1.5rem;
    font-style: italic;
    margin: 1.3rem;
    padding: 1rem;
}
.about-services {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.about-services-card { 
    width: 100%;
    margin: 0.5rem;
    margin-inline: auto;
    height: 100%;
}
.about-services-card-title {
    font-size: 0.8rem;
}
.about-services-image {
    width: 100%;
    height: 200px;
    object-fit: fill;
}
.brands {
    justify-content: center;
}
.about-brands {
    width: 100px;
    height: 50px;
    margin: 1rem;
}
/* Projects component */
.projects-main{
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content:space-around
}
.projects {
    margin:auto;
}
.projects-card {
    margin: 1rem;
    width: 100%;
    height: 100%;
}
.swiper-image {
    object-fit: fill;
}
/* Contact component */
.contact {
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
}
.contact-container {
    width: min(60%, 500px);
}
.contact-container h3 {
    font-size: 1.5rem;
    text-align: center;
}
.contact-container h6 {
    font-size: 1rem;
}
.contactForm label {
    font-size: 1.2rem;
}
.contactForm button {
    padding: 0.6rem;
    font-size: 1.2rem;
}
.contact-card {
    margin: 1rem auto 1rem;
    width: 40%;
    height: 350px;
}
.contact-card h5 {
    font-size: 1.25rem;
}
.contact-card address {
    font-size: 0.8rem;
}
.contact-card p {
    font-size: 0.8rem;
}
.contact-card a {
    font-size: 0.8rem;
}
}
